import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function LinkedIn({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM4 4L20 4V20H4V4ZM8 18V9.5H6V18H8ZM6 6H8V8H6V6ZM12 9.5V10.2655C12.643 9.83021 13.4717 9.5 14.5 9.5C15.3 9.5 15.9665 9.70287 16.5 10.0586C17.0273 10.4101 17.3664 10.8717 17.5819 11.3028C17.7955 11.73 17.8974 12.1451 17.9473 12.445C17.9727 12.5973 17.9858 12.7264 17.9926 12.8213C17.996 12.869 17.9978 12.9085 17.9988 12.9386C17.9993 12.9537 17.9996 12.9664 17.9998 12.9767L18 12.9903L18 12.9956L18 12.9979L18 12.999C18 12.9995 18 13 17 13H18V18H16V13.007L15.9999 13.0052C15.9997 12.9987 15.9991 12.9845 15.9977 12.9638C15.9947 12.9221 15.9882 12.8558 15.9745 12.7738C15.9464 12.6049 15.892 12.395 15.7931 12.1972C15.6961 12.0033 15.5664 11.8399 15.3906 11.7227C15.221 11.6096 14.95 11.5 14.5 11.5C13.622 11.5 12.9972 11.9285 12.5558 12.419C12.3344 12.665 12.1719 12.9144 12.0653 13.1024C12.0401 13.147 12.0183 13.1875 12 13.2229V18H10V13V9.5H12Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default React.memo(LinkedIn)
